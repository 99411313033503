import { PropsWithChildren } from "react";

import Footer from "components/Footer";
import Nav from "components/Nav";

// Same as defaultLayout except the nav is dark (black background, white text)
const ClearLayout = ({ children, home }: PropsWithChildren<{ home?: boolean }>) => {
  return (
    <>
      <Nav clear home={home} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default ClearLayout;
