import HomeMisc from "components/Home/HomeMisc";
import HomeProjects from "components/Home/HomeProjects";
import ClearLayout from "components/Layouts/Clear";
import SEO from "components/SEO";

// Animation speed control
const SPEED: number = 0.2;

const Home = () => {
  return (
    <ClearLayout home>
      <div className="v-home">
        <SEO
          title="Mobile App Development & Digital Transformation Company"
          description="More than an app development company: Fueled is an award-winning technology consultancy that transforms businesses by generating ideas, building products, and accelerating growth."
        />

        {/* PROJECTS */}
        <HomeProjects speed={SPEED} />

        {/* MISC (Secret, Press, Investments) */}
        <HomeMisc speed={SPEED} />

        {/* SCROLL BAR */}
        {/* Why wrapper? We want height: -webkit-fill-available on iOS, and you can't */}
        {/* take a percentage of that (-webkit-fill-available * .7) so we wrap and make */}
        {/* the child (.scroller) height: 70% */}
        <div id="scroller" className="v-home__scroller">
          <div className="scroller">
            <div id="track" className="scroller__track bg-light" />
            <div id="bar" className="scroller__bar bg-white" />
          </div>
        </div>
      </div>
    </ClearLayout>
  );
};

export default Home;
